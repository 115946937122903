import React from 'react';
import {ExternalLink, Container, Heading} from 'app/components/start/startStyles';
import {APPLE_APP_STORE_URL, GOOGLE_PLAY_STORE_URL} from 'app/components/banner/notificationVars';
import styled from 'styled-components';

const BADGE_HEIGHT = 40;

const Image = styled.img`
  margin: ${BADGE_HEIGHT / 4}px; // surrounding must to be a 1/4 of the height with a min-height of 40px
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StoreBadges = () => {
  return (
    <Container>
      <Heading variant='subheading2' component='span'>
        Download
      </Heading>
      <Wrapper>
        <ExternalLink href={APPLE_APP_STORE_URL} target='_blank'>
          <Image height={BADGE_HEIGHT} alt='Laden im App Store' src='/badge/app-store-badge.svg' />
        </ExternalLink>
        <ExternalLink href={GOOGLE_PLAY_STORE_URL} target='_blank'>
          <Image height={BADGE_HEIGHT + 12} alt='Jetzt bei Google Play' src='/badge/google-play-badge.svg' />
        </ExternalLink>
      </Wrapper>
    </Container>
  );
};

export {StoreBadges};
