import styled from 'styled-components';
import {COLORS, Typography} from '@fupa/fupa-uikit';
import {FPHTMLLink} from 'app/components/links/link';

const Heading = styled(Typography)`
  &&& {
    padding-left: 0.5rem;
    color: ${COLORS.white};
  }
`;

const ExternalLink = styled(FPHTMLLink)`
  text-decoration: none;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-width: 10rem;
  text-align: center;
  ${props => props.theme.desktopLayout`
    text-align: left;
   `}
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0.5rem;
  margin-top: 0.5rem;

  li {
    padding: 0.375rem 0;
  }
`;

const LinkText = styled(Typography)`
  &&& {
    color: ${COLORS.white};
  }
`;

export {Heading, ExternalLink, Container, List, LinkText};
