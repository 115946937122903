import React from 'react';
import {Button, COLORS, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import styled from 'styled-components';

const L_LAPTOP_WIDTH = 1440;
const PROMO_SECTION_MAX_WIDTH = 450;

const SCSections = styled.section`
  display: flex;
  background-color: ${COLORS.white};
  flex-direction: column;
  justify-content: center;
  max-width: ${L_LAPTOP_WIDTH}px;
  margin: 1rem auto;

  ${props => props.theme.desktopLayout`
    margin: 2rem auto;
    justify-content: center;
    flex-direction: row;

    ${PromoSection}:last-child {
      margin-left: 3rem;
      };
  `}
`;

const PromoSection = styled.div`
  display: flex;
  padding: 1.5rem 1rem;
  flex-direction: column;
  max-width: ${PROMO_SECTION_MAX_WIDTH}px;
  margin: auto;
`;

const PromoContent = styled.div`
  display: flex;
  padding-bottom: 1.5rem;
  justify-content: space-between;
`;

const PromoImg = styled.img`
  margin-left: 0.75rem;
`;

const PromoHeadline = styled(Typography)`
  &&& {
    padding-bottom: 1.25rem;
  }
`;

const Sections = () => {
  return (
    <SCSections>
      <PromoSection>
        <PromoHeadline variant='headline2'>Dein Einstieg über die Region</PromoHeadline>
        <PromoContent>
          <Typography variant='subheading'>
            Amateurfußball ist regional! Und FuPa ist das auch! Wähle deine Heimat-Region. Dein schnellster Weg zu
            deinen Ligen, News und vielem mehr!
          </Typography>
          <PromoImg src='/RegionSelectPromo.svg' height={100} width={100} alt='Region select' />
        </PromoContent>
        <LinkOnComponent to='/select'>
          <Button label='Zur Regionsauswahl' />
        </LinkOnComponent>
      </PromoSection>
      <PromoSection>
        <PromoHeadline variant='headline2'>Finde deinen Verein, deinen Spieler uvm.</PromoHeadline>
        <PromoContent>
          <Typography variant='subheading'>
            Du willst noch schneller ans Ziel kommen? Dann nutze unsere Suche - nach SpielerInnen, Vereinen, Ligen und
            Turnieren!
          </Typography>
          <PromoImg src='/SearchPromo.svg' height={100} width={100} alt='Region select' />
        </PromoContent>
        <LinkOnComponent to='/search'>
          <Button label='Zur Suche' />
        </LinkOnComponent>
      </PromoSection>
    </SCSections>
  );
};

export {Sections};
