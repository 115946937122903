import React from 'react';
import styled from 'styled-components';
import {SocialFollows} from 'app/components/start/SocialFollows';
import {COLORS} from '@fupa/fupa-uikit';
import {StoreBadges} from 'app/components/start/StoreBadges';
import {Service} from 'app/components/start/Service';
import {Legals} from 'app/components/start/Legals';
import {About} from 'app/components/start/About';
import {desktopContentWidth} from 'app/styles/media';
import {Copyright} from 'app/components/start/Copyright';
import {windowIsDefined} from 'app/helpers/windowIsDefined';
import {useNative} from 'app/contexts/NativeContext';

const Container = styled.div`
  width: 100%;
  bottom: 0;
  padding-top: 2rem;
  margin-bottom: -4.5rem; // overwrite body style
  background-color: ${COLORS.darkGrey};
`;

const Content = styled.div`
  display: flex;
  margin: auto;
  max-width: ${desktopContentWidth}px;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const Footer = () => {
  const {isNativeApp} = useNative();
  const showStoreBadges = windowIsDefined && !isNativeApp ? <StoreBadges /> : null;

  return (
    <Container>
      <Content>
        <SocialFollows />
        {showStoreBadges}
      </Content>
      <Content>
        <Service />
        <Legals />
        <About />
      </Content>
      <Content>
        <Copyright />
      </Content>
    </Container>
  );
};

export {Footer};
