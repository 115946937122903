import React, {useEffect, useState} from 'react';
import {LinkOnComponent} from 'app/components/links/link';
import {IconButton} from '@mui/material';
import {SearchOutlined} from '@mui/icons-material';
import {Button, COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';

const SCROLL_Y_THRESHOLD = 60;
const APP_BAR_HEIGHT = 72;
const L_LAPTOP_WIDTH = 1440;
const HERO_TEXT_WIDTH = 400;
const HERO_IMAGE_MAX_HEIGHT = 600;

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 25rem;
  height: 60vh;
  width: 100%;
  background: linear-gradient(225deg, #005b98 0%, ${COLORS.fupaPrimary} 100%);
  ${props => props.theme.desktopLayout`
    align-items: center;
    min-height: 37rem;
  `}
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: ${APP_BAR_HEIGHT / 16}rem;
  background-color: ${props => (props.animate ? COLORS.fupaPrimary : 'transparent')};
  box-shadow: ${props => (props.animate ? `0 0 ${SIZES['16']} 0 ${COLORS.fupaPrimaryFocused}` : 'none')};
  transition: background-color 0.6s cubic-bezier(0.16, 0, 0.12, 1);
  border-bottom: ${props => (props.animate ? 'none' : `1px solid ${COLORS.lightestGrey}`)};
`;

const HeaderContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 1.5rem;
  color: ${COLORS.white};
`;

const HeroTextContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  max-width: ${HERO_TEXT_WIDTH}px;
  text-align: center;
  margin: auto;
  ${props => props.theme.desktopLayout`
    &&& {
      padding: 0;
      text-align: left;
      margin: 2rem 0 0 5rem;
    }
  `}
`;

const SCHeroElement = styled.div`
  width: inherit;
  position: absolute;
  max-width: ${L_LAPTOP_WIDTH}px;
  margin: ${(APP_BAR_HEIGHT * 1.5) / 16}rem auto 0 auto;
  height: calc(60vh - ${APP_BAR_HEIGHT * 1.5}px - 0.5rem);
  max-height: ${HERO_IMAGE_MAX_HEIGHT}px;
  background: url(/heroimage@1000w.png) no-repeat right bottom;
  background-size: contain;
  ${props => props.theme.desktopLayout`
    background: url(/heroimage@2000w.png) no-repeat right bottom;
    background-size: contain;
    margin-top: ${(APP_BAR_HEIGHT * 2) / 16}rem;
  `}
`;

const Claim = styled(Typography)`
  ${props => props.theme.desktopLayout`
    &&& {
      font-size: 1rem;
    }
  `}
`;

const Headline = styled(Typography)`
  margin-bottom: 0.75rem;
  &&& {
    color: ${COLORS.white};
  }
  ${props => props.theme.desktopLayout`
    &&& {
      font-size: 2.25rem;
    }
  `}
`;

const Paragraph = styled(Typography)`
  &&& {
    color: ${COLORS.white};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  a {
    text-decoration: none;
  }

  button {
    box-shadow: none;
  }

  & button:last-child {
    margin-right: 0.75rem;
  }

  ${props => props.theme.desktopLayout`
    &&& {
      justify-content: flex-start;
    }
  `}
`;

const HeroElement = ({fupaClaim}) => {
  const [animate, setAnimate] = useState(false);

  const onScroll = () => {
    setAnimate(window.scrollY >= SCROLL_Y_THRESHOLD);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <HeroContainer>
      <SCHeroElement>
        <Header animate={animate}>
          <HeaderContent>
            <img src={'/FuPa_Logo.svg'} width={124} height={45} />
            <LinkOnComponent to={'/search'}>
              <IconButton color='inherit' aria-controls='search' size='large'>
                <SearchOutlined color='inherit' />
              </IconButton>
            </LinkOnComponent>
          </HeaderContent>
        </Header>
        <HeroTextContainer>
          <Claim component='h2' variant={'button-primary'}>
            {fupaClaim}
          </Claim>
          <Headline component='h1' variant='headline2'>
            Deine Community-Plattform für den Amateurfußball
          </Headline>
          <Paragraph variant='body1'>
            Präsentiere die Spiele deines Teams auf FuPa, deine eigene Spieler-Statistik - in Echtzeit via Liveticker.
            <br />
            FuPa ist die Heimat des Amateurfußballs, DIE Bühne für den “echten” Fußball!
          </Paragraph>
          <ButtonContainer>
            <a data-google-interstitial='false' href={`/auth/signup`}>
              <Button label='Registrieren' />
            </a>
            <a data-google-interstitial='false' href={`/auth/login`}>
              <Button label='Anmelden' secondary />
            </a>
          </ButtonContainer>
        </HeroTextContainer>
      </SCHeroElement>
    </HeroContainer>
  );
};

export {HeroElement};
