import React, {useReducer} from 'react';
import styled from 'styled-components';
import {COLORS} from '@fupa/fupa-uikit';
import {useLocation} from 'react-router';
import {Footer} from 'app/components/start/Footer';
import {Helmet} from 'react-helmet-async';
import {Sections} from 'app/components/start/Sections';
import {HeroElement} from 'app/components/start/HeroElement';
import {fetchRegionsStart} from 'app/routes/start/StartPageActions';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {StartPageReducer} from 'app/routes/start/StartPageReducer';
import {Regions} from 'app/components/start/Regions';

const fupaClaim = 'Aus Liebe zum Fußball';

const Page = styled.div`
  background-color: ${COLORS.white};
`;

const StartPage = () => {
  const location = useLocation();
  const meta = [
    {
      name: 'description',
      content:
        'Präsentiere die Spiele deines Teams auf FuPa, deine eigene Spieler-Statistik - in Echtzeit via Liveticker. FuPa ist die Heimat des Amateurfußballs, DIE Bühne für den “echten” Fußball!',
    },
    {
      name: 'robots',
      content: 'index',
    },
  ];
  let canonical = location.pathname === '/start' ? <link rel='canonical' href={`https://www.fupa.net/`} /> : null;

  const {restoredData} = useRestoreData('StartPage');
  const initialState = restoredData ?? {
    regions: {
      isFetching: false,
      items: [],
    },
    error: null,
  };
  const [state, dispatch] = useReducer(StartPageReducer, initialState);

  const loadData = () => fetchRegionsStart()(dispatch, state);
  const {
    regions: {items: regions, isFetching},
    error,
  } = state;
  useFetchData(state, loadData, 'StartPage');

  return (
    <Page>
      <Helmet title={`FuPa ⚽️ - ${fupaClaim}`} meta={meta}>
        {canonical}
      </Helmet>
      <HeroElement fupaClaim={fupaClaim} />
      <Sections />
      <Regions regions={regions} isFetching={isFetching} error={error} />
      <Footer />
    </Page>
  );
};

export default StartPage;
