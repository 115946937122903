import {Container, ExternalLink, Heading, List, LinkText} from 'app/components/start/startStyles';
import React from 'react';

const Service = () => {
  return (
    <Container>
      <Heading variant='subheading2' component='span'>
        Service
      </Heading>
      <List>
        <li>
          <ExternalLink href='https://support.fupa.net/home' target='_blank'>
            <LinkText variant='subheading'>Hilfebereich</LinkText>
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href='https://medium.com/fupa' target='_blank'>
            <LinkText variant='subheading'>Blog</LinkText>
          </ExternalLink>
        </li>
      </List>
    </Container>
  );
};

export {Service};
