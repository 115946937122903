import React from 'react';
import styled from 'styled-components';
import {LinkText} from 'app/components/start/startStyles';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const Copyright = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <Container>
      <img src={'/fupa-logo-white.svg'} width={124} height={45} />
      <LinkText variant='subheading'>© Copyright 2006 - {year} FuPa GmbH</LinkText>
    </Container>
  );
};

export {Copyright};
