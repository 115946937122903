import React from 'react';
import {Container, Heading, List, LinkText} from 'app/components/start/startStyles';
import {LinkOnComponent} from 'app/components/links/link';

const Legals = () => {
  return (
    <Container>
      <Heading variant='subheading2' component='span'>
        Rechtliches
      </Heading>
      <List>
        <li>
          <LinkOnComponent to={'/about/terms-of-use'}>
            <LinkText variant='subheading'>Nutzungsbedingungen</LinkText>
          </LinkOnComponent>
        </li>
        <li>
          <LinkOnComponent to={'/about/privacy-policy'}>
            <LinkText variant='subheading'>Datenschutz</LinkText>
          </LinkOnComponent>
        </li>
        <li>
          <LinkOnComponent to={'/about/legal-notice'}>
            <LinkText variant='subheading'>Impressum</LinkText>
          </LinkOnComponent>
        </li>
      </List>
    </Container>
  );
};

export {Legals};
