import React from 'react';
import styled from 'styled-components';
import {Container, Heading, ExternalLink} from 'app/components/start/startStyles';

const Image = styled.img`
  margin: 0.5rem;
`;

const config = [
  {
    icon: '/social/f-icon.svg',
    link: 'https://www.facebook.com/FuPaNet/',
    name: 'Facebook',
  },
  {
    icon: '/social/ig-icon.svg',
    link: 'https://www.instagram.com/fupanet/',
    name: 'Instagram',
  },
  {
    icon: '/social/wa-icon.svg',
    link: 'https://wa.me/4915730181578',
    name: 'WhatsApp',
  },
  {
    icon: '/social/tiktok-icon.svg',
    link: 'https://www.tiktok.com/@fupanet',
    name: 'TikTok',
  },
  {
    icon: '/social/yt-icon.svg',
    link: 'https://www.youtube.com/user/FuPa2006',
    name: 'YouTube',
  },
];

const SocialFollows = () => {
  const follows = config.map(elem => (
    <ExternalLink key={elem.name} href={elem.link} target='_blank'>
      <Image src={elem.icon} alt={elem.name} />
    </ExternalLink>
  ));

  return (
    <Container>
      <Heading variant='subheading2' component='span'>
        Folge uns
      </Heading>
      <div>{follows}</div>
    </Container>
  );
};

export {SocialFollows};
