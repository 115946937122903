import {catchDataError} from 'app/helpers/apiHelpers';
import {api} from 'app/services/api';
import {updateHistory} from 'app/actions/historyActions';

const getRegions = () => {
  return api.get(`/regions`);
};

function fetchingRegions() {
  return {
    type: 'REGIONS_START_FETCHING',
  };
}

function updateRegions(data) {
  return {
    type: 'REGIONS_START_UPDATE',
    data,
  };
}

function errorRegions(data) {
  return {
    type: 'REGIONS_START_ERROR',
    data,
  };
}

function fetchRegionsStart() {
  return function(dispatch, state) {
    const {items} = state.regions;
    if (items.length > 0) {
      return;
    }
    dispatch(fetchingRegions());
    return getRegions()
      .then(response => {
        dispatch(updateRegions(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(errorRegions(errorData));
      });
  };
}

function fetchRegionsStartSSR() {
  return function(dispatch) {
    dispatch(fetchingRegions());
    return getRegions()
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'StartPage',
            data: {regions: {isFetching: false, items: response.data}, districts: {}, error: null},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(
          updateHistory('undefined', {
            store: 'StartPage',
            data: {regions: {items: [], isFetching: false}, districts: {}, error: errorData},
          })
        );
      });
  };
}

export {fetchRegionsStart, fetchRegionsStartSSR};
