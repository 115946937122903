import {Container, ExternalLink, Heading, List, LinkText} from 'app/components/start/startStyles';
import React from 'react';

const About = () => {
  return (
    <Container>
      <Heading variant='subheading2' component='span'>
        Über uns
      </Heading>
      <List>
        <li>
          <ExternalLink href='http://fupa.gmbh/' target='_blank'>
            <LinkText variant='subheading'>Unternehmen</LinkText>
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href='http://fupa.gmbh/jobs/' target='_blank'>
            <LinkText variant='subheading'>Jobs</LinkText>
          </ExternalLink>
        </li>
        <li>
          <ExternalLink href='https://support.fupa.net/support/tickets/new' target='_blank'>
            <LinkText variant='subheading'>Kontakt</LinkText>
          </ExternalLink>
        </li>
      </List>
    </Container>
  );
};

export {About};
