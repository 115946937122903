const StartPageReducer = (state, action) => {
  switch (action.type) {
    case 'REGIONS_START_FETCHING':
      return {...state, regions: {...state.regions, isFetching: true}};
    case 'REGIONS_START_UPDATE':
      return {...state, regions: {...state.regions, isFetching: false, items: action.data}};
    case 'REGIONS_START_ERROR':
      return {...state, error: action.data};
    default:
      return state;
  }
};

export {StartPageReducer};
